<template>
    <div>
        <hero-global title="Exposiciones" img="/img/expositions/expositions-1.svg" first-width="75" last-width="25" />
        <div class="container p-x-2-rem">
            <div class="grid-2 align-items-center">
                <div class="grid">
                    <h2>Últimas exposiciones</h2>
                    <span class="underline m-left-22"></span>
                </div>
            </div>
            <div class="grid-3">

                <card-new :img="exposition.image.url" width="33" :schedule="''" v-for="(exposition, key) in store.expositions" :key="key" :value="key" :title="''" :enlace="'/exposiciones/' + exposition.slug">
                    <template v-slot:subtitle>
                        <p class="title-24">{{exposition.name}}</p>
                       
                    </template>
                </card-new>

            </div>
            <br />
            <br />
        
            <br />
            <br />
            <section class="p-2-rem">
                <other-links-interest class="p-1-rem shadow" />
            </section>
            <br />
        </div>

    </div>
</template>

<script>
    import HeroGlobal from "@/components/Global/HeroGlobal";
    import CardNew from "@/components/Card/CardNew";
    import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
    import { contentStore } from '@/stores/contents'
    import moment from 'moment'
    export default {
        name: "expositions",
        components: {OtherLinksInterest, CardNew, HeroGlobal },
        data: () => ({
            dataInfo: []
        }),
        setup() {
            const store = contentStore();
            store.loadExpositions();
            return {
                store
            }
        },
        mounted() {
           

        },
        methods: {
            currentDateTime(date) {
                return moment(date).locale('es').format('L')
            }
        }
    }
</script>

<style scoped lang="scss">
    .box-date {
        p {
            font-weight: 300;
            padding-left: 30px;
            margin-bottom: 15px;
        }

        .category-location {
            background-size: 18px;
        }
    }

    @import "../styles/icons";
</style>